
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Loader from './components/loader';

const Home = lazy(() => import('./pages/Home'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const Login = lazy(() => import('./pages/Login'));
const Registration = lazy(() => import('./pages/Registration'));
const ArficialIntelligence = lazy(() => import('./pages/services/AI.service'));
const BlockChain = lazy(() => import('./pages/services/BlockChain.service'));
const DataScience = lazy(() => import('./pages/services/DataScience.service'));
const Product = lazy(() => import('./pages/Product'));
const Careers = lazy(() => import('./pages/Careers'));
const OurTeam = lazy(() => import('./pages/OurTeam'));
const Alliances = lazy(() => import('./pages/Alliances'));
const Events = lazy(() => import('./pages/Event'));
function App() {
  return (
    <Router>
      {/* Suspense wraps the lazy loaded components */}
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Registration />} />
          <Route path="/product" element={<Product />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/our-team" element={<OurTeam />} />
          <Route path="/alliances" element={<Alliances/>} />
          <Route path="/Events" element={<Events/>} />
          <Route path="/services/AI" element={<ArficialIntelligence />} />
          <Route path="/services/blockchain" element={<BlockChain />} />
          <Route path="/services/data-science" element={<DataScience />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;

